*, *:before, *:after {
  box-sizing: border-box;
}
html {
  background-color: #092448;
  background-image:
      radial-gradient(
          #092448,
          #000000
      );
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-size: 16px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  color: #fff;
  width: 100%;
  height:100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.container {
  width: 100%;
  max-width: 1060px;
  padding: 0 20px;
  margin:0 auto;
}
.card {
  margin: 40vh auto 0;
  padding: 20px 50px;
  transform: rotateX(20deg);
  color: #fff;
  opacity:.9;
}
.site-heading {
  text-align: center;
}
.site-heading h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
.site-heading h2 {
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: #fff;
  text-decoration: none;
}
