.nav {
    position: absolute;
    bottom: 20px;
    right: 10px;
}
.nav .menu-item {
    margin-bottom: 5px;
    padding: 5px 10px;
}
.nav a {
    font-weight: 100;
}
@media (min-width: 768px) {
    .nav {
        bottom: 30px;
    }
    .nav .menu-item {
        font-size: 1.2rem;
        padding: 5px 15px;
    }
}
